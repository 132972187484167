import React, { useState } from "react"
// import GitexPopupModal from "../../core/GitexPopupModal"

const GitexTwoSection = () => {
    const [openTCdisruptGitex, setOpenTCdisruptGitex] = useState(false)
    const [openMWCGitex, setOpenMWCGitex] = useState(false)
    const openTCdisruptGitexModal = () => {
        setOpenTCdisruptGitex(true)
    }
    const closeTCdisruptGitexModal = () => {
        setOpenTCdisruptGitex(false)
    }
    const openMWCGitexModal = () => {
        setOpenMWCGitex(true)
    }
    const closeMWCGitexModal = () => {
        setOpenMWCGitex(false)
    }
    return (
        <>
            {/* {openTCdisruptGitex && <GitexPopupModal open={openTCdisruptGitex} closeGitexModal={closeTCdisruptGitexModal} url={"https://calendly.com/webmob/meet-us-in-tcdisrupt"}/>}
            {openMWCGitex && <GitexPopupModal open={openMWCGitex} closeGitexModal={closeMWCGitexModal} url={"https://calendly.com/webmob/meet-us-in-mwc"}/>} */}
         
            <div
                className={`w-full bg-cover bg-center min-h-screen overflow-hidden flex justify-center bg-blue-200`}
                
            >
                <div className="w-full flex lg:flex-row flex-col justify-center items-center min-h-screen max-w-7xl py-8 md:py-16 lg:py-20 xl:py-20 gap-4">
                    <div className="lg:w-6/12 w-full flex justify-center items-center lg:px-3 px-4 border-r-2 border-slate-400">
                        <div className="flex justify-center flex-col ">
                            <div className="h-full w-auto flex lg:justify-start justify-center lg:mt-0 mt-12">
                                <img
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/08/Tech-Crunch-logo-1.png"
                                    alt="TC Disrupt"
                                    title="TC Disrupt"
                                    height="110px"
                                    width="440px"
                                    className="h-full w-auto"
                                    loading="lazy"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: `Tech Crunch <br> Disrupt`,
                                    }}
                                    className="font-serif font-semibold lg:text-6xl text-3xl tracking-normal pt-10 text-shark-500 lg:text-left text-center"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <p className="font-semibold lg:text-xl md:text-xl text-lg font-sans mt-6 text-shark-400 px-2 rounded lg:text-left text-center">
                                    <div class="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mb-2 px-2 bg-blue-600 rounded lg:text-left text-center">Booth: M24</div>
                                </p>
                            </div>
                           
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Moscone Center - West Hall, San Francisco`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 px-2 rounded lg:text-left text-center"
                                />
                            </div>
                           
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `19 to 21 September 2023`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 mt-6 px-2 rounded lg:text-left text-center"
                                />
                            </div>
                          
                            {/* <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Meet us at: Hall: D, Booth: #6 - 55735  Las Vegas, NV.`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mt-4 px-2 bg-blue-600 rounded lg:text-left text-center"
                                />
                            </div> */}
                            <div className="flex justify-center lg:justify-start w-full mt-3">
                                <button
                                    className="mt-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                                    // onClick={openTCdisruptGitexModal} // remove after gitex2022
                                >
                                    Meet Us At Tech Crunch Disrupt
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-6/12 w-full flex justify-center items-center lg:px-3 px-4">
                        <div className="flex justify-center flex-col ">
                            <div className="h-full w-auto flex lg:justify-start justify-center lg:mt-0 mt-12">
                                <img
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/08/MWC-logo.png"
                                    alt="Mobile World Congress"
                                    title="Mobile World Congress"
                                    height="110px"
                                    width="440px"
                                    className="h-full w-auto"
                                    loading="lazy"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <h1
                                    dangerouslySetInnerHTML={{
                                        __html: `Mobile World Congress`,
                                    }}
                                    className="font-serif font-semibold lg:text-6xl text-3xl tracking-normal pt-10 text-shark-500 lg:text-left text-center"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <p className="font-semibold lg:text-xl md:text-xl text-lg font-sans mt-6 text-shark-400 px-2 rounded lg:text-left text-center">
                                    <div class="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mb-2 px-2 bg-blue-600 rounded lg:text-left text-center">Booth: 1630</div>
                                </p>
                            </div>
                           
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Convention Center - West Hall, Las Vegas`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 px-2 rounded lg:text-left text-center"
                                />
                            </div>
                            <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `26 to 28 September 2023`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-shark-400 mt-6 px-2 rounded lg:text-left text-center"
                                />
                            </div>
                          
                            {/* <div className="flex lg:justify-start justify-center">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: `Meet us at: Hall: D, Booth: #6 - 55735  Las Vegas, NV.`,
                                    }}
                                    className="font-semibold lg:text-xl md:text-xl text-lg font-sans text-white mt-4 px-2 bg-blue-600 rounded lg:text-left text-center"
                                />
                            </div> */}
                            <div className="flex justify-center lg:justify-start w-full mt-3">
                                <button
                                    className="mt-4 md:my-8 lg:mt-6 bg-yellow-500 relative px-8 py-3 text-base leading-6 font-semibold text-shark-500  hover:bg-yellow-600 rounded"
                                    // onClick={openMWCGitexModal} // remove after gitex2022
                                >
                                    Meet Us At Mobile World Congress
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GitexTwoSection
